import React, { useState } from 'react';
import IMG_BANNER from 'images/jumbotron-news.png';
import PropTypes from 'prop-types';
import SlideUp from 'components/animation/SlideUp';
import { graphql, useStaticQuery, Link } from 'gatsby';
import './styles.css';
import CustomContainer from '../../CustomContainer';
import reactHtmlParser from 'react-html-parser';

const CardCustom = ({ item }) => {
  const [isShow, setIsShow] = useState(false);
  const dateSplit = item.date.split('-');
  const convertMonth = () => {
    const date = new Date();
    date.setMonth(Number(dateSplit[1]) - 1);
    return date
      .toLocaleString('en-US', {
        month: 'long',
      })
      .toUpperCase();
  };
  return (
    <div
      className="card-custom grid grid-cols-12 gap-4 pb-8 pb-12 sm:pb-14 my-5"
      style={{
        borderBottom: '1px solid white',
      }}
    >
      <div className="col-span-3 sm:col-span-2 flex flex-col justify-start items-center text-center">
        <p className="text-sm line-clamp-1 w-full">{convertMonth()}</p>
        <p className="text-lg leading-tight">{dateSplit[2]}</p>
        <p className="text-sm">{dateSplit[0]}</p>
      </div>
      <div className="title-news col-span-9 sm:col-span-8">
        <Link to={`/news/${item.slug}`}>
          <p
            className="line-clamp-1 sm:line-clamp-2 text-[18px] sm:text-base cursor-pointer
            hover:underline font-semibold sm:font-extralight"
            onMouseEnter={() => setIsShow(true)}
            onMouseLeave={() => setIsShow(false)}
          >
            {item.title}
          </p>
        </Link>
        <p className="text-sm mt-2 line-clamp-3">
          {reactHtmlParser(item.content)}
        </p>
        <div className="block mt-3 sm:hidden">
          <Link to={`/news/${item.slug}`}>
            <p className="pt-3 text-sm underline">Read News</p>
          </Link>
        </div>
      </div>
      <div className="hidden small-text ml-8 show-image sm:grid col-span-2 relative">
        <Link to={`/news/${item.slug}`}>
          <div className="flex justify-center items-center h-full">
            <p className="text-sm z-10 cursor-pointer hover:underline">
              Read News
            </p>
          </div>
          <div
            className={`image-thumbnails cursor-pointer ${
              isShow && 'show-true'
            }`}
          >
            <img src={item.src} alt={item.title} />
          </div>
        </Link>
      </div>
    </div>
  );
};

const renderCardList = (data) => {
  return data.map((item) => {
    return <CardCustom item={item?.node} key={item?.index} />;
  });
};

const News = () => {
  const DATAS = useStaticQuery(graphql`
    query {
      allNewsJson(sort: { order: DESC, fields: date }) {
        edges {
          node {
            content
            date
            slug
            src
            title
          }
        }
      }
    }
  `);

  const NEWS_LIST = DATAS.allNewsJson.edges;

  return (
    <div className="bg-brand-black text-brand-white font-extralight min-h-screen pt-[70px] sm:pt-0 news-root">
      {/* jumbotron */}
      <div
        className="h-[25vh] sm:h-screen flex justify-center items-center px-5 sm:px-10"
        style={{
          backgroundImage: `url(${IMG_BANNER})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <SlideUp>
          <p
            className="text-[24px] sm:text-[42px] text-center font-light container mx-auto w-full sm:w-[80%]"
            style={{
              textShadow: '0px 0px 12px rgba(251, 252, 228, 0.25)',
            }}
          >
            Stay up to date with our latest{' '}
            <b className="font-semibold">breakthroughs.</b>
          </p>
        </SlideUp>
      </div>
      {/* News list section */}
      <CustomContainer className="py-14 sm:py-32">
        {NEWS_LIST?.length ? (
          <div className="grid sm:px-14 xl:px-20">
            {renderCardList(NEWS_LIST)}
          </div>
        ) : (
          <div className="text-base sm:text-md flex items-center justify-center">
            Sorry, there is no news entry yet
          </div>
        )}
      </CustomContainer>
    </div>
  );
};

CardCustom.propTypes = {
  item: PropTypes.shape(),
};

export default News;

import React from 'react';
import Pages from 'components/Pages';
import News from 'components/pages/news';

const NewsPage = () => {
  return (
    <Pages>
      <News />
    </Pages>
  );
};

export default NewsPage;
